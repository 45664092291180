import React from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();

  const handleLaunchClick = () => {
    navigate('/game');
  };

  return (
    <div className="LandingPage">
      <header className="LandingPage-header">
        <h1>Welcome to the Game!</h1>
        <button onClick={handleLaunchClick} className="launch-button" aria-label="Launch Game">Launch Game</button>
      </header>
    </div>
  );
};

export default LandingPage;
