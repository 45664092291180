import React from 'react';
import './Game.css';

const Game = () => {
  return (
    <div className="Game">
      <header className="Game-header">
        <div className="Game-info">
          <h1>Welcome to the Game!</h1>
          <p>Your objective is to collect as many points as possible by completing various challenges. Use the controls below to navigate and play the game. Good luck!</p>
          <div className="Game-stats">
            <span>Score: 0</span>
            <span>Player: Player1</span>
          </div>
        </div>
        <p>Game content goes here...</p>
      </header>
    </div>
  );
};

export default Game;
